import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import mergeEdges from '../../../utils/mergeEdges'
import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Caption, Text } from '../../shared/Typography'

const yellowIcon = require('../../../images/icons/playbook/yellow.svg')
const purpleIcon = require('../../../images/icons/playbook/purple.svg')
const pinkIcon = require('../../../images/icons/playbook/pink.svg')
const greenIcon = require('../../../images/icons/playbook/green.svg')

const icons = [yellowIcon, purpleIcon, pinkIcon, greenIcon]

export interface ISection {
  title: string
  description: string
  cta: string
  ctaLink: string
}

export interface IProp {
  allContentYaml: {
    edges: {
      node: { playbook: { sections: ISection[] } }
    }
  }
}

const Sections = () => {
  const { allContentYaml }: IProp = useStaticQuery(query)
  const data = mergeEdges(allContentYaml)

  if (!data) {
    return null
  }

  return (
    <Container>
      <Flex flexWrap="wrap" justifyContent="space-between" mb="4em">
        {data.playbook.sections.map((e: ISection, index: number) => {
          const width = ['100%', 'calc(100% / 2 - .7em)']

          return (
            <Box key={e.title} width={width} mb="1em">
              <Card>
                <Flex>
                  <div>
                    <img src={icons[index]} alt={e.title} />
                  </div>
                  <Caption ml=".7em" mb="1rem">
                    {e.title}
                  </Caption>
                </Flex>

                <Text mb="1.5rem" color="grey">
                  {e.description}
                </Text>

                <Link color="blue" to={e.ctaLink} withIcon="arrow">
                  {e.cta}
                </Link>
              </Card>
            </Box>
          )
        })}
      </Flex>
    </Container>
  )
}

const query = graphql`
  query playbookSections {
    allContentYaml {
      edges {
        node {
          playbook {
            sections {
              title
              description
              cta
              ctaLink
            }
          }
        }
      }
    }
  }
`

export default Sections
