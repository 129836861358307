import * as React from 'react'

import Hero from '../components/Playbook/Hero'
import Sections from '../components/Playbook/Sections'
import CodeOfConduct from '../components/Playbook/CodeOfConduct'
import SEO from '../components/SEO'
import Newsletter from '../components/Newsletter'
import Layout from '../layouts'

const PlaybookPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Sections />
      <CodeOfConduct />
      <Newsletter />
    </Layout>
  )
}

export default PlaybookPage
