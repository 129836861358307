import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import { Description, MainTitle } from '../../shared/Typography'
import styled from '../../styled'

const PlaybookImage = styled.div`
  position: absolute;
  width: 20em;
  right: -5em;
  bottom: -10em;
  z-index: -9;

  > div {
    width: 100%;
  }
`

export interface IHero {
  title: string
  description: string
}

export interface IProp {
  allContentYaml: {
    edges: {
      node: { playbook: IHero }
    }
  }
}

const Hero = () => {
  const { allContentYaml }: IProp = useStaticQuery(query)
  const data = mergeEdges(allContentYaml)

  if (!data) {
    return null
  }

  return (
    <Container>
      <Flex
        alignItems="center"
        justifyContent="center"
        mt="4rem"
        mb={['4em', '5em']}
      >
        <Box maxWidth="550px">
          <MainTitle textAlign="center" mb="1rem">
            {data.playbook.title}
          </MainTitle>
          <Description textAlign="center" mb="1rem" color="greyMedium">
            {data.playbook.description}
          </Description>
        </Box>
        <PlaybookImage>
          <Image path="playbookHero" alt={data.playbook.title} />
        </PlaybookImage>
      </Flex>
    </Container>
  )
}

export default Hero

const query = graphql`
  query playbookHero {
    allContentYaml {
      edges {
        node {
          playbook {
            title
            description
          }
        }
      }
    }
  }
`
