import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import mergeEdges from '../../../utils/mergeEdges'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Description, Title } from '../../shared/Typography'

export interface IConduct {
  codeOfConduct: {
    title: string
    description: string
    cta: string
    ctaLink: string
  }
}

export interface IProp {
  allContentYaml: {
    edges: {
      node: { playbook: IConduct }
    }
  }
}

const CodeOfConduct = () => {
  const { allContentYaml }: IProp = useStaticQuery(query)
  const data = mergeEdges(allContentYaml)

  if (!data) {
    return null
  }

  return (
    <Container mb={['2em', '5em']}>
      <Flex
        justifyContent="center"
        mb={['2em', '6em']}
        px={['0', '6em', '16em']}
      >
        <Box maxWidth="760px">
          <Flex justifyContent="center" flexDirection="column">
            <Title textAlign="center" mb="1rem">
              {data.playbook.codeOfConduct.title}
            </Title>

            <Description textAlign="center" color="greyMedium" mb="1rem">
              {data.playbook.codeOfConduct.description}
            </Description>

            {data.playbook.codeOfConduct.ctaLink &&
              data.playbook.codeOfConduct.cta && (
                <Link
                  color="blue"
                  to={data.playbook.codeOfConduct.ctaLink}
                  withIcon="arrow"
                  childrenProps={{ justifyContent: 'center' }}
                >
                  {data.playbook.codeOfConduct.cta}
                </Link>
              )}
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

export default CodeOfConduct

const query = graphql`
  query playbookConduct {
    allContentYaml {
      edges {
        node {
          playbook {
            codeOfConduct {
              title
              description
              cta
              ctaLink
            }
          }
        }
      }
    }
  }
`
